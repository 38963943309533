
export default function openTab(tabName) {
    const contentTabs = document.getElementsByClassName("content-tab");
    for (let i = 0; i < contentTabs.length; i++) {
        contentTabs[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tab");
    for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace("is-active", "");
    }

    const activeElement = document.getElementById(tabName)
    activeElement.style.display = "block";

    const tabElement = document.getElementById(tabName + 'Tab');
    tabElement.className += " is-active";
}
